<template>
    <div class="pageItem">
        <h2>Текущий забег <strong>#{{ raceData.gameId }}</strong></h2>
        <p :class="['raceTimer', raceData.status]">{{ formattedStatus }}: {{ raceData.timer ? raceData.timer : 0 }}</p>
        <div class="raceOnline">
            <p v-for="(horse, index) in sortedHorses" :key="horse.name">
                {{ index + 1 }}. Лошадь <span :class="`horse${horse.name.slice(-1)}`">#{{ horse.name.slice(-1) }}</span>
            </p>
        </div>
    </div>
    <div ref="raceVisualRef" :class="['raceVisual', raceData.status]">
        <div class="raceFinish" v-if="raceData.status === 'finished'">
            <h3 class="finishTitle">Winner!</h3>
            <div class="horseWinner">
                <div :class="['horse', sortedHorses[0].name]"></div>
            </div>
            <p :class="[sortedHorses[0].name]">#{{ sortedHorses[0].name.slice(-1) }}</p>
        </div>
        <div class="raceImage" :style="{ 'transform': `translateX(-${raceData.racePosition}px)` }"></div>
        <div v-for="horse in horses" :key="horse.name" :class="['horse', horse.name]"
            :style="{ left: `${calculatePosition(horse.position)}px`, animationDuration: `${horse.delay}s` }">
        </div>
    </div>
    <div class="pageItem yourBets">
        <h2 class="_flex">
            Ваши ставки
            <router-link class="moreLink" to="/">История ставок<ArrowRight /></router-link>
        </h2>
        <div class="raceBets" v-if="userBets.length > 0">
            <p v-for="bet in userBets.slice(0,2)" :key="bet.id">
                Забег <span class="raceNumber">#{{ bet.game_id }}</span> <strong>{{ bet.count }}Б.</strong> на Лошадь
                <span :class="`horse${bet.horse}`">#{{ bet.horse }}</span>
            </p>
        </div>
        <p class="empty" v-else>
            Вы ещё не делали ставки
        </p>
    </div>
    <form @submit.prevent="placeBet" class="pageItem">
        <h2>Выберите лошадь</h2>
        <div class="horsePicker">
            <div v-for="horse in horses" :key="horse.name"
                :class="['picker', { '_active': selectedHorse === horse.name }]" @click="selectedHorse = horse.name">
                #{{ horse.name.slice(-1) }}
            </div>
        </div>
        <h2>Выберите множитель ставки</h2>
        <div class="betMultiplier">
            <p v-for="multiplier in betMultipliers" :key="multiplier"
                :class="[{ '_active': selectedMultiplier === multiplier }]" @click="selectedMultiplier = multiplier">
                x{{ multiplier }}
            </p>
        </div>
        <button class="btn" :disabled="isBetDisabled">Сделать ставку</button>
    </form>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import MessageComponent from '@/components/MessageComponent.vue';
import ArrowRight from '@/files/icons/ArrowRight.vue';

export default {
    name: 'RaceView',
    components: {
        MessageComponent,
        ArrowRight
    },
    props: {
        raceData: {
            type: Object,
            required: true
        },
        userData: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const selectedHorse = ref('horse1');
        const selectedMultiplier = ref(1);
        const raceVisualRef = ref(null);
        const containerWidth = ref(0);
        const maxPosition = 258;
        const betMultipliers = [1, 5, 10, 50, 100];
        const userBets = ref([]);
        const showMessage = ref(false);
        const messageType = ref('');
        const messageText = ref('');

        const betAmount = computed(() => selectedMultiplier.value);

        const placeBet = async () => {
            if (!selectedHorse.value) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = 'Пожалуйста, выберите лошадь';
                return;
            }
            try {
                const response = await axios.post('https://hamster-coin.xyz:8088/placeBet', {
                    game_id: props.raceData.gameId,
                    user_id: props.userData.id,
                    horse: selectedHorse.value.slice(-1),
                    count: betAmount.value
                });

                if (response.status === 200) {
                    showMessage.value = true;
                    messageType.value = 'good';
                    messageText.value = 'Ставка успешно создана!';
                    userBets.value = response.data.betsHistory;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        const updateContainerWidth = () => {
            if (raceVisualRef.value) {
                containerWidth.value = raceVisualRef.value.offsetWidth;
            }
        };

        const calculatePosition = (position) => {
            const stopPosition = maxPosition - 80;
            const maxHorsePosition = Math.max(...props.raceData.horses.map(horse => horse.position));
            const scaledPosition = (position / maxPosition) * containerWidth.value;
            if (props.raceData.status !== 'waiting') {
                if (scaledPosition >= stopPosition) {
                    return stopPosition - (maxHorsePosition - position);
                } else {
                    return scaledPosition;
                }
            } else {
                return -20;
            }
        };

        const getUserBets = async () => {
            try {
                const response = await axios.post('https://hamster-coin.xyz:8088/getUserBets', {
                    userId: props.userData.id
                });

                if (response.status === 200) {
                    userBets.value = response.data;
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.message;
            }
        };

        const clearMessage = () => {
            showMessage.value = false;
            messageType.value = '';
            messageText.value = '';
        };

        onMounted(() => {
            updateContainerWidth();
            window.addEventListener('resize', updateContainerWidth);
            getUserBets();
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateContainerWidth);
        });

        watch(() => props.userData, () => {
            getUserBets();
        });

        useHead({
            title: 'Текущий забег'
        });

        return {
            selectedHorse,
            selectedMultiplier,
            betAmount,
            placeBet,
            raceVisualRef,
            updateContainerWidth,
            calculatePosition,
            getUserBets,
            clearMessage,
            userBets,
            showMessage,
            messageType,
            messageText,
            betMultipliers
        };
    },
    computed: {
        sortedHorses() {
            return [...this.raceData.horses].sort((a, b) => b.position - a.position);
        },
        formattedStatus() {
            return this.raceData.status.charAt(0).toUpperCase() + this.raceData.status.slice(1);
        },
        horses() {
            return this.raceData.horses;
        },
        isBetDisabled() {
            const hasBetOnCurrentRace = this.userBets.length > 0 && this.userBets[0].game_id === this.raceData.gameId;
            return this.raceData.status !== 'waiting' || hasBetOnCurrentRace || this.raceData.wsStatus === 'off';
        }
    }
};
</script>