<template>
  <div class="gamePage" v-if="appStore.user.id">
    <HeaderLayout :userData="userData" />
    <div class="pageContent">
      <router-view :raceData="raceData" :userData="userData" />
      <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
    </div>
    <MenuLayout />
  </div>
</template>

<script>
import HeaderLayout from '@/components/layouts/HeaderLayout.vue'
import MenuLayout from '@/components/layouts/MenuLayout.vue'
import MessageComponent from '@/components/MessageComponent.vue';
import { useAppStore } from '@/stores/app';
import { onMounted, ref } from 'vue';
import { useWebApp, useWebAppViewport } from 'vue-tg';

export default {
  name: 'App',
  components: {
    HeaderLayout,
    MenuLayout,
    MessageComponent
  },
  setup() {
    const appStore = useAppStore();
    const webApp = useWebApp();
    const webAppViewport = useWebAppViewport();
    const socket = ref(null);
    const showMessage = ref(false);
    const messageType = ref('');
    const messageText = ref('');
    const raceData = ref({
      status: 'waiting',
      horses: [
        { name: 'horse1', position: -20, delay: 1 },
        { name: 'horse2', position: -20, delay: 1 },
        { name: 'horse3', position: -20, delay: 1 },
        { name: 'horse4', position: -20, delay: 1 },
        { name: 'horse5', position: -20, delay: 1 },
        { name: 'horse6', position: -20, delay: 1 },
      ],
      timer: 0,
      gameId: 0,
      racePosition: 0,
      wsStatus: 'off'
    });
    const userData = ref({
      id: 0,
      username: 'name',
      horsehoe: 0,
      ticket: 0
    });

    onMounted(async () => {
      webApp.ready();
      webAppViewport.expand();
      try {
        await appStore.init();
      } catch (error) {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = 'Ошибка инициализации данных пользователя';
        return;
      }
      if (!appStore.user.id) {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = 'ID пользователя не установлен';
        return;
      }
      socket.value = new WebSocket('wss://hamster-coin.xyz:8088');
      socket.value.onopen = () => {
        console.log('Соединение установлено');
        const userId = appStore.user.id;
        socket.value.send(JSON.stringify({ type: 'userId', userId: userId }));
      };
      socket.value.onmessage = (event) => {
        const data = JSON.parse(event.data);
        raceData.value = data.raceData || raceData.value;
        userData.value = data.userData || userData.value;
      };
      socket.value.onerror = () => {
        showMessage.value = true;
        messageType.value = 'bad';
        messageText.value = 'Ошибка получения данных';
      };
      socket.value.onclose = () => {
        console.log('Соединение закрыто');
      };
    });

    const clearMessage = () => {
      showMessage.value = false;
      messageType.value = '';
      messageText.value = '';
    };

    return {
      appStore,
      raceData,
      userData,
      clearMessage,
      showMessage,
      messageType,
      messageText,
    }
  }
}
</script>

<style scoped>
@import '@/css/App.css';
</style>