<template>
    <div class="menuLayout">
        <div class="menuItem">
            <router-link to="/">
                <HomeIcon />
                <span>Main</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/race">
                <HorseIcon />
                <span>Race</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/packs">
                <PacksIcon />
                <span>Packs +</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/tasks">
                <TaskIcon />
                <span>Tasks</span>
            </router-link>
        </div>
        <div class="menuItem">
            <router-link to="/partners">
                <PartnerIcon />
                <span>Partners</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import HomeIcon from '@/files/icons/HomeIcon.vue';
import HorseIcon from '@/files/icons/HorseIcon.vue';
import PartnerIcon from '@/files/icons/PartnerIcon.vue';
import TaskIcon from '@/files/icons/TaskIcon.vue';
import PacksIcon from '@/files/icons/PacksIcon.vue';
export default {
    name: 'MenuLayout',
    components: {
        HomeIcon,
        HorseIcon,
        PartnerIcon,
        TaskIcon,
        PacksIcon
    },
}
</script>