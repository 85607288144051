<template>
    <header class="header">
        <div class="userElement">
            <div class="userIcon"><HorseHoe /></div>
            <p>{{userData.username}}</p>
            <router-link to="/settings">
                <SettingsIcon />
            </router-link>
        </div>
        <div class="userBalances">
            <p class="balance">
                <GoldHorseHoe />
                <span>{{userData.horsehoe}}</span>
            </p>
            <span class="separator"></span>
            <p class="balance">
                <TicketIcon />
                <span>{{userData.ticket}}</span>
            </p>
        </div>
    </header>
</template>

<script>
import HorseHoe from '@/files/icons/HorseHoe.vue';
import GoldHorseHoe from '@/files/icons/GoldHorseHoe.vue';
import TicketIcon from '@/files/icons/TicketIcon.vue';
import SettingsIcon from '@/files/icons/SettingsIcon.vue';
export default {
    name: 'HeaderLayout',
    components: {
        HorseHoe,
        GoldHorseHoe,
        TicketIcon,
        SettingsIcon
    },
    props: {
        userData: {
            type: Object,
            required: true
        }
    }
}
</script>