<template>
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M378.776 95.2461L176.107 180.58L256 214.219L458.669 128.885L378.776 95.2461Z"
            fill="var(--gold2)" />
        <path
            d="M351.286 83.6709L260.141 45.2939C258.83 44.7422 257.423 44.458 256.001 44.458C254.578 44.458 253.171 44.7422 251.86 45.2939L53.3311 128.885L148.617 169.005L351.286 83.6709Z"
            fill="var(--gold2)" />
        <path
            d="M266.667 232.877V467.542L462.808 384.956C464.742 384.141 466.393 382.773 467.554 381.025C468.714 379.276 469.334 377.224 469.334 375.125V147.543L266.667 232.877Z"
            fill="var(--gold2)" />
        <path
            d="M245.333 232.877L42.667 147.543V375.125C42.6673 377.224 43.2866 379.276 44.4473 381.025C45.6081 382.773 47.2589 384.141 49.193 384.956L245.334 467.542L245.333 232.877Z"
            fill="var(--gold2)" />
    </svg>
</template>

<script>
export default {
    name: 'PacksIcon'
}
</script>