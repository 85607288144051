import axios from 'axios';
import { useWebApp } from 'vue-tg';

const user = useWebApp().initDataUnsafe?.user;
const telegramId = user?.id ?? 111;
const username = user?.first_name ?? 'username';

export async function getOrCreateUser() {
    try {
        const initData = useWebApp().initData;
        const params = new URLSearchParams((initData) ? initData : '');
        let partner = params.get('start_param');
        partner = partner === null ? 0 : partner;
        const response = await axios.post('https://hamster-coin.xyz:8088/getOrCreateUser', {
            telegramId,
            username,
            partner
        });
        return response.data;
    } catch (error) {
        return {error: 'Ошибка при получении пользователя'}
    }
}