<template>
    <div class="pageItem">
        <h2>Текущий забег</h2>
        <button @click.prevent="getInvoiceLink">Тестовые звёзды</button>
    </div>
    <MessageComponent v-if="showMessage" :type="messageType" :message="messageText" @message-cleared="clearMessage" />
</template>

<script>
import { useHead } from '@vueuse/head';
import { ref } from 'vue';
import axios from 'axios';
import MessageComponent from '@/components/MessageComponent.vue';
import { useWebAppNavigation } from 'vue-tg';
export default {
    name: 'PackView',
    components: {
        MessageComponent
    },
    props: {
        userData: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const showMessage = ref(false);
        const messageType = ref('');
        const messageText = ref('');
        const webAppNavigation = useWebAppNavigation();
        useHead({
            title: 'Приобретение билетов'
        });
        
        const getInvoiceLink = async () => {
            try {
                const response = await axios.post('https://hamster-coin.xyz:8088/getInvoiceLink', {
                    telegramId: props.userData.telegram_id,
                });

                if (response.status === 200) {
                    const invoiceLink = response.data;
                    webAppNavigation.openInvoice(invoiceLink, (url, status) =>{
                        console.log(url)
                        console.log(status)
                    })
                }
            } catch (error) {
                showMessage.value = true;
                messageType.value = 'bad';
                messageText.value = error.response.data.error;
            }
        };

        const clearMessage = () => {
            showMessage.value = false;
            messageType.value = '';
            messageText.value = '';
        };

        return {
            getInvoiceLink,
            clearMessage,
            showMessage,
            messageType,
            messageText,
        };
    }
};
</script>